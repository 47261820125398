'use strict';


(function (app)
{
    app.controller('LoginCtrl',[
        '$scope', '$location', 'api', 'alerts',
        function ($scope, $location, api, alerts)
        {
            $scope.pending_check = true;

            $scope.authenticating = false;

            api.isAuthenticated()
                .then(function (result)
                {
                    if (result.authenticated)
                    {
                        $scope.$parent.can_configure = result.can_configure;
                        $scope.$emit('authenticated', true);
                    }
                    else
                    {
                        $scope.pending_check = false;
                    }
                })
                .catch(function ()
                {
                    $scope.pending_check = false;
                    $scope.$emit('authenticated', false);
                    //TODO Something Here
                    console.log();
                });


            $scope.login = function()
            {
                $scope.authenticating = true;

                if(!$scope.username || !$scope.password)
                {
                    alerts.error('Missing Requirements', 'All fields need to be filled in!');
                    $scope.authenticating = false;
                    return;
                }

                api.sendAuthentication($scope.username, $scope.password)
                    .then(function(result)
                    {
                        $scope.authenticating = false;

                        if (result.authenticated)
                        {
                            $scope.$parent.can_configure = result.can_configure;
                            $scope.$emit('authenticated', true);
                        }
                        else
                        {
                            $scope.password = '';
                            alerts.error('Failed to Login', 'Your login credentials are invalid. Please try again.');
                        }
                    })
                    .catch(function(result)
                    {
                        $scope.authenticating = false;

                        //TODO
                        console.log(result);
                    });
            }
        }
    ]);
})(window.NewOaklandDashboard);