/**
 * Created by mwillard on 10/4/16.
 */
(function (app) {
    app.directive('hiddenLarge', function () {
        return {
            restrict: 'A',
            scope: {
                hideme:"=hideme"
            },
            link: function (scope, elem, attrs) {


            }
        }
    });
})(window.NewOaklandDashboard);