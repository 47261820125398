/**
 * Created by mwillard on 10/6/16.
 */
(function (app) {
    app.controller('HealthTechCtrl', [
        '$scope', 'NgTableParams', 'api', 'alerts', '$location', '$sce', '$httpParamSerializer',
        function ($scope, NgTableParams, api, alerts, $location, $sce, $httpParamSerializer) {
            $scope.is_loading = false;
            $scope.loading_providers = true;
            $scope.loading_statuses = true;
            $scope.selectable_status = [];
            $scope.get_data_error = '';
            $scope.date = {from:new Date(moment().subtract(1,'month').format('MM/DD/YYYY')),thru:new Date(moment().format('MM/DD/YYYY'))};

            $scope.overall = {has:0,total:0,score:0};

            $scope.filters = {};

            $scope.totals = {};

            $scope.$watch('date', function()
            {
                $scope.tableParams.reload();
            }, true);

            $scope.getExport = function () {
                var url = $scope.tableParams.url();

                for (var i in $scope.filters) {
                    if ($scope.filters.hasOwnProperty(i)) {
                        var value = $scope.filters[i];

                        if (value !== '') {
                            url['filter[' + i + ']'] = value;
                        }
                    }
                };

                if ($scope.tableParams.total() > 10000) {
                    alerts.confirm(
                        'Warning',
                        'There are more than 10000 records in this export. It may take a long to generate. Are you sure you want to continue?' +
                        ' It is recommended that you filter the results reduce to the number of records.',
                        function () {
                            window.open('/api/data/health-tech?export=1&' + $httpParamSerializer(
                                    {
                                        export: 1,
                                        count: $scope.tableParams.count(),
                                        page: $scope.tableParams.page(),
                                        filter: $scope.tableParams.filter(),
                                        sorting: $scope.tableParams.sorting(),

                                        provider: $scope.selected_provider
                                    }
                                )
                            );
                        },
                        function () {
                        }
                    );
                }
                else {
                    window.open('/api/data/health-tech?export=1&' + $httpParamSerializer(
                            {
                                export: 1,
                                count: $scope.tableParams.count(),
                                page: $scope.tableParams.page(),
                                filter: $scope.tableParams.filter(),
                                sorting: $scope.tableParams.sorting(),

                                provider: $scope.selected_provider
                            }
                        )
                    );
                }
            };

            $scope.tableParams = new NgTableParams({
                sorting: {},
                count: 20
            }, {
                paginationMaxBlocks: 20,
                counts: [],
                getData: function (params) {

                    $scope.is_loading = true;
                    $scope.get_data_error = '';
                    return api.healthTech(
                        {
                            count: params.count(),
                            page: params.page(),
                            filter: params.filter(),
                            sorting: params.sorting(),
                            date:$scope.date,
                            provider: $scope.selected_provider
                        }
                    )
                        .then(function (results) {
                            params.total(results.total);

                            for(var i = 0; i < results.records.length; i++)
                            {
                                $scope.overall.has += Number(results.records[i].has);
                                $scope.overall.total += Number(results.records[i].total);

                                results.records[i].score = ((Number(results.records[i].has) / Number(results.records[i].total)) * 100).toFixed(2);

                            }
                            $scope.overall.score = (($scope.overall.has / $scope.overall.total) * 100).toFixed(2);
                            console.log($scope.overall);
                            $scope.is_loading = false;

                            return results.records;
                        })
                        .catch(function (error) {
                            $scope.is_loading = false;
                            alerts.error('Error', 'Unable to pull from the database! Please try again!');
                        });
                }

            });
            $scope._formatPercent = function(data)
            {
                return $sce.trustAsHtml( ((data.has / data.total) * 100).toFixed(2) + "%");
            }
        }
    ])
})(window.NewOaklandDashboard);