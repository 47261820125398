'use strict';


(function (app)
{
    app.controller('HomeCtrl', [
        '$scope', '$location',
        function ($scope, $location)
        {
            $location.path('/charts');
        }
    ]);
})(window.NewOaklandDashboard);
