/**
 * .
 */
(function(app)
{
    app.controller('ContinuityCareCtrl',[
        '$scope', 'NgTableParams', 'api', '$httpParamSerializer', '$timeout', 'alerts','$location','$sce', '$routeParams',
        function ($scope, NgTableParams, api, $httpParamSerializer, $timeout, alerts,$location, $sce, $routeParams)
        {
            $scope.is_loading = false;
            $scope.loading_providers = true;
            $scope.loading_statuses = true;
            $scope.selectable_status = [];
            $scope.get_data_error = '';
            $scope.filters = {};
            $scope.date = {from:new Date(moment().subtract(1,'month').format('MM/DD/YYYY')),thru:new Date(moment().format('MM/DD/YYYY'))};
            $scope.providers = [
                {provider_id:"-1", provider_full_name:'All Providers'}
            ];
            $scope.selected_provider = "-1";
            (function()
            {
                api
                    .getProviders()
                    .then(function(results)
                    {
                        angular.forEach(results.providers, function(value)
                        {
                            if(value.provider_full_name.indexOf(' Outpatient') !== -1) {
                                $scope.providers.push(value);
                            }
                        });

                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    })
            })();

            $scope.totals = {};
            $scope.$watch('date', function()
            {
                $scope.tableParams.reload();
            }, true);
            $scope.$watch('selected_provider', function()
            {
                $scope.tableParams.reload();
            }, true);
            $scope._check_time = function(row)
            {
                if(row.coc_date == null || row.coc_date == "")
                    return "red_bg";

                var start = moment(row.coc_date);

                var time_diff = Math.abs(moment.duration(
                    moment(row.AppointmentDate).diff(start,"hour", true)));

                if (time_diff >= 24 && time_diff < 72)
                    return "salmon_bg";
                if(time_diff >= 72)
                    return "red_bg";

                return "";
            };
            $scope.doFilter = function ()
            {
                $scope.tableParams.reload();
            };

            $scope.getExport = function ()
            {
                var url = $scope.tableParams.url();

                for (var i in $scope.filters)
                {
                    if ($scope.filters.hasOwnProperty(i))
                    {
                        var value = $scope.filters[i];

                        if (value !== '')
                        {
                            url['filter[' + i +']'] = value;
                        }
                    }
                };

                if ($scope.tableParams.total() > 10000)
                {
                    alerts.confirm(
                        'Warning',
                        'There are more than 10000 records in this export. It may take a long to generate. Are you sure you want to continue?' +
                        ' It is recommended that you filter the results reduce to the number of records.',
                        function (){
                            window.open('/api/data/continuity-care?export=1&' + $httpParamSerializer(
                                    {
                                        export:1,
                                        count:$scope.tableParams.count(),
                                        page:$scope.tableParams.page(),
                                        filter:$scope.tableParams.filter(),
                                        sorting:$scope.tableParams.sorting(),
                                        date:$scope.date,
                                        provider:$scope.selected_provider
                                    }
                                )
                            ); },
                        function (){}
                    );
                }
                else
                {
                    window.open('/api/data/continuity-care?export=1&' + $httpParamSerializer(
                            {
                                export:1,
                                count:$scope.tableParams.count(),
                                page:$scope.tableParams.page(),
                                filter:$scope.tableParams.filter(),
                                sorting:$scope.tableParams.sorting(),
                                date:$scope.date,
                                provider:$scope.selected_provider
                            }
                        )
                    );
                }
            };
            $scope._format_date = function(date)
            {
                if(date != null && date != "")
                    return $sce.trustAsHtml(moment(date).format('MM/DD/YYYY'));
                return "Not Found!";
            };
            $scope.tableParams = new NgTableParams({
                sorting: { },
                count: 10
            }, {
                paginationMaxBlocks: 10,
                counts:[],
                getData: function (params)
                {

                    $scope.is_loading = true;
                    $scope.get_data_error = '';
                    return api.getContinuityCare(
                        {
                            count:params.count(),
                            page:params.page(),
                            filter:params.filter(),
                            sorting:params.sorting(),
                            date:$scope.date,
                            provider:$scope.selected_provider
                        }
                    )
                        .then(function(results)
                        {
                            params.total(results.total);
                            $scope.is_loading = false;
                            $scope.totals = results.totals;

                            return results.records;
                        })
                        .catch(function(error)
                        {
                            $scope.is_loading = false;
                            alerts.error('Error', 'Unable to pull from the database! Please try again!');
                        });
                }

            });
        }
    ]);
})(window.NewOaklandDashboard);