(function(app) {
    'use strict';
    app.controller('ChartTypeCtrl', [
        '$scope', 'api',
        function ($scope, api)
        {
            $scope.chartConfig = {
                options: {
                    chart: {
                        type: 'column'
                    },
                    tooltip: {
                        valuePrefix: '$',
                    }
                },
                title: {
                    text: false
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    title: {
                        text: $scope.type_title
                    }
                },
                series: [],
                size: {
                    height: 300
                }
            };

            $scope.loading = false;
            $scope.$watch('timeframe', getData);
            $scope.$watch('type', getData);

            var current_request = false;

            function getData()
            {

                if ($scope.loading && current_request && current_request.cancel)
                {
                    current_request.cancel();
                }

                $scope.loading = true;
                current_request = api
                    .getTypeData($scope.timeframe, $scope.type)
                    .then(function (data)
                    {
                        $scope.loading = false;
                        $scope.chartConfig.yAxis.title.text = $scope.type_label;
                        $scope.chartConfig.xAxis.categories = data.categories;
                        $scope.chartConfig.options.tooltip.valuePrefix = $scope.valuePrefix;
                        $scope.chartConfig.options.tooltip.valueSuffix = $scope.valueSuffix;

                        $scope.chartConfig.series = [
                            {name: 'Payment ' + " (" + $scope.type_label + ")", data: data.series.paid},
                            {name: 'Writeoff ' + " (" + $scope.type_label + ")", data: data.series.open}
                        ]
                    })
                    .catch (function ()
                    {
                        $scope.loading = false;
                    });
            }
        }]);
})(window.NewOaklandDashboard);
