'use strict';


(function (app)
{
    app.controller('ConfigureUsersCtrl', [
        '$scope', 'api', 'alerts',
        function ($scope, api, alerts)
        {
            // $scope.user = {};
            $scope.usrs = [];
            $scope.getUser = getUser;
            $scope.listUsers = listUsers;
            $scope.createUser = createUser;
            $scope.removeUser = removeUser;
            $scope.openCreateUser = openCreateUser;
            $scope.prettyAuthName = prettyAuthName;
            $scope.showCreateUser = false;
            $scope.listUsers();


            function listUsers()
            {
                api.listUsers()
                    .then(function (users)
                    {
                        $scope.usrs = users['users'];
                    })
                    .catch(function (error)
                    {
                        alerts.error('API Call Error', error);
                    });
            }

            function prettyAuthName(id)
            {
                id = Number(id);
                switch(id)
                {
                    case 1:
                        return 'Admin';
                    case 2:
                        return 'Manager';
                    case 3:
                        return 'User';
                    default:
                        return 'Unknown User Type';
                }
            }

            function getUser()
            {
                api.singleUser(1)
                    .then(function (user)
                    {
                        $scope.user = user;
                    })
                    .catch(function (error)
                    {
                        alerts.error('API Call Error', error);
                    });
            }

            function createUser()
            {
                api.createUsers($scope.newUserName, $scope.newUserEmail, $scope.newUserPassword, $scope.newUserConfirm, $scope.newPermLevel)
                    .then(function (user)
                    {
                        listUsers();
                        $scope.showCreateUser = false;
                    }).catch(function (error)
                {
                    alerts.error('User Creation Error', error);
                });
            }

            function removeUser(user_id)
            {
                console.log(user_id);
                api.removeUsers(user_id)
                    .then(function (delSuccess)
                    {
                        $scope.usrs = _.reject($scope.usrs, function (d)
                        {
                            return d.id === user_id;
                        });
                    }).catch(function (error)
                {
                    alerts.error('Couldnt Delete User', error);
                });
            }

            function openCreateUser()
            {
                $scope.showCreateUser = !$scope.showCreateUser;
                $scope.newUserName = '';
                $scope.newUserEmail = '';
                $scope.newUserPassword = '';
                $scope.newUserConfirm = '';
                $scope.newPermLevel = 1;
            }

        }
    ]);
})(window.NewOaklandDashboard);
