'use strict';


(function (app)
{
    app.filter('email',
        function ()
        {
            return function(raw, simple)
            {
                if (!raw) { return ''; }

                if (simple === true)
                {
                    return raw;
                }
                else
                {
                    return '<a href="mailto:' + raw + '">' + raw + '</a>';
                }

            }
        }
    );


})(window.NewOaklandDashboard);