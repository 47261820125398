/**
 * Created by mwillard on 10/6/16.
 */
(function(app)
{
    app.controller('BusinessProcessQualityCtrl', [
        '$scope', 'api', 'alerts', 'highchartsNG',
        function($scope, api, alerts, highchartsNG)
        {
            $scope.dates =
            {
                from:new Date(moment().subtract(2,'week').format('MM/DD/YYYY')),
                thru:new Date(moment().format('MM/DD/YYYY'))
            };

            $scope.apt_data = [];
            $scope.new_clients = [];
            $scope.loading = true;
            $scope.active_php = 0;
            $scope.active_op = 0;

            $scope.get_data = function()
            {
                $scope.loading = true;
                api.businessQuality({dates:$scope.dates})
                    .then(function(results)
                    {
                        $scope.new_clients.length = 0;
                        $scope.apt_data.length = 0;
                        for(var i = 0; i < results.apt.length; i++)
                        {
                            var apt = results.apt[i];
                            $scope.apt_data.push(
                                {
                                    name:apt.Status != null ? apt.Status : 'Unknown Status Type',
                                    y:Number(apt.total)
                                }
                            );
                        }
                        $scope.labels = [];
                        // for(var i = 0; i < results.new_clients.length; i++)
                        // {
                        //     var time = results.new_clients[i];
                            // $scope.labels.push(time.date);
                            // $scope.new_clients.push(Number(time.total));
                        // }
                        $scope.new_client_series = [];
                        console.log(results.new_clients_all);
                        var dates = [];

                        for(var i in results.new_clients_all)
                        {
                            var provider = results.new_clients_all[i];
                            for(var ii = 0; ii < provider.dates.length; ii++)
                            {
                                dates.push(Number(provider.dates[ii].total));
                            }
                            $scope.labels.push(provider.provider_name);

                        }

                        $scope.new_client_series.push(
                            {
                                name: 'New Clients #',
                                data:dates,
                            }
                        );
                        //console.log(JSON.stringify($scope.new_client_series));
                        console.log($scope.new_client_series);
                        $scope.active_op = results.active_op;
                        $scope.active_php = results.active_php;
                        $scope.loading = false;
                        highchartsNG.ready(function()
                        {
                            $scope.appointments_config = {
                                "options": {
                                    "chart": {
                                        "type": "pie"
                                    },
                                    "plotOptions": {
                                        "series": {
                                            "stacking": ""
                                        }
                                    }
                                },
                                plotOptions: {
                                    pie: {
                                        allowPointSelect: true,
                                        cursor: 'pointer',
                                        dataLabels: {
                                            enabled: false
                                        },
                                        showInLegend: true
                                    }
                                },
                                "series": [{
                                    name:"Appointments Volume",
                                    id: 'appointments',
                                    colorByPoint: true,
                                    data: $scope.apt_data,
                                    type:"pie",
                                }
                                ],
                                "title": {
                                    "text": ""
                                },
                                "credits": {
                                    "enabled": false
                                },
                                "loading": false,
                                "size": {}

                            };


                            $scope.new_clients_config = {
                                options: {
                                    chart: {
                                        type: 'bar'
                                    },
                                    tooltip: {
                                        style: {
                                            padding: 10,
                                            fontWeight: 'bold'
                                        }
                                    }
                                },
                                series: $scope.new_client_series,
                                xAxis: {
                                    categories: $scope.labels
                                },
                                title: {
                                    text: ''
                                }
                                /*options: {
                                 chart: {
                                 type: "line"
                                 },
                                 },
                                 xAxis: {
                                 categories: $scope.labels,
                                 labels: {
                                 rotation: -45,
                                 style: {
                                 fontSize: '13px',
                                 fontFamily: 'Verdana, sans-serif'
                                 }
                                 }
                                 },

                                 yAxis: {
                                 min: 0,
                                 max: 1000,
                                 title: {
                                 text: 'New Clients'
                                 }
                                 },

                                 series: $scope.new_client_series,
                                 title: {
                                 text: ""
                                 },
                                 credits: {
                                 enabled: false
                                 },
                                 loading: false,
                                 size: {}
                                 */
                            };
                        });
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            };

            $scope.get_data();




        }
    ])
})(window.NewOaklandDashboard);