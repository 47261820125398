'use strict';


(function (app)
{
    app.filter('bCurrency', ['$filter', function ($filter)
    {
        return function (amount, symbol, fractionSize)
        {
            if (amount === '' || amount === null)
            {
                return '';
            } else
            {
                return $filter("currency")(amount, symbol, fractionSize);
            }
        };
    }]);
})(window.NewOaklandDashboard);