'use strict';


(function (app)
{
    app.filter('mysqlDatetime',
        function ()
        {
            return function (input, output)
            {
                if (input === '' || input === null)
                {
                    return '';
                }
                else
                {
                    var date_part, date_parts, day, hour, minute, month, parts, second, time_part, time_parts, year;
                    output = output || 'MM/DD/YYYY h:mm a';
                    parts = input.split(' ');
                    date_part = parts[0];
                    time_part = parts[1];
                    date_parts = date_part.split('-');
                    time_parts = time_part.split(':');
                    year = date_parts[0];
                    month = date_parts[1] - 1;
                    day = date_parts[2];
                    hour = time_parts[0];
                    minute = time_parts[1];
                    second = time_parts[2];
                    return moment([year, month, day, hour, minute, second]).format(output);
                }
            }
        });

    app.filter('mysqlDate',
        function ()
        {
            return function (input, output)
            {
                if (input === '' || input === null)
                {
                    return '';
                }
                else
                {
                    var date_parts, day, month, year;
                    input = input || '0000-00-00';
                    output = output || 'MM/DD/YYYY';
                    date_parts = input.split('-');
                    year = date_parts[0];
                    month = date_parts[1] - 1;
                    day = date_parts[2];
                    return moment([year, month, day]).format(output);
                }
            }
        });


})(window.NewOaklandDashboard);
