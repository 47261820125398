'use strict';

(function ()
{
    window.NewOaklandDashboard = angular.module('NewOaklandDashboard', [
        'ngAnimate',
        'ngMaterial',
        'ngRoute',
        'ngSanitize',
        'ui.bootstrap',
        'highcharts-ng',
        'ngTable',
        'monospaced.elastic',
        'internationalPhoneNumber',
        'CompiledTemplates',
        'angular.filter',
        'oitozero.ngSweetAlert'
    ]).config([
        '$routeProvider', '$locationProvider', 'highchartsNGProvider',
        function ($routeProvider, $locationProvider, highchartsNGProvider)
        {

            $routeProvider.when('/', {
                redirectTo: '/charts'
            });

            $routeProvider.when('/home', {
                redirectTo: '/charts'
            });

            $routeProvider.when('/login', {
                templateUrl: '/templates/login.html',
                controller: 'LoginCtrl'
            });
            //Charts
            $routeProvider.when('/charts', {
                redirectTo:'/charts/revenue'
            });
            $routeProvider.when('/charts/revenue', {
                templateUrl: '/templates/charts.html',
                controller: 'ChartsCtrl'
            });
            $routeProvider.when('/charts/site', {
                templateUrl: '/templates/site_charts.html',
                controller: 'SiteChartsCtrl'
            });
            $routeProvider.when('/charts/care', {
                templateUrl: '/templates/charts/care.html',
                controller: 'CareChartsCtrl'
            });
            //
            //Reports
            $routeProvider.when('/report/revenue', {
                templateUrl: '/templates/report/revenue.html',
                controller: 'ReportRevenueCtrl'
            });

            $routeProvider.when('/report/charges', {
                templateUrl: '/templates/report/charges.html',
                controller: 'ReportChargesCtrl'
            });

            $routeProvider.when('/report/clients', {
                templateUrl: '/templates/report/clients.html',
                controller: 'ReportClientsCtrl'
            });
            $routeProvider.when('/report/calls',
            {
                    templateUrl: '/templates/report/calls.html',
                    controller: 'IncomingCallsCtrl'
            });
            $routeProvider.when('/report/therapy',
            {
                templateUrl: '/templates/report/therapy.html',
                controller: 'TherapyCtrl'
            });
            $routeProvider.when('/report/first_therapy',
                {
                    templateUrl: '/templates/report/first_therapy.html',
                    controller: 'FirstTherapyCtrl'
                });
            $routeProvider.when('/report/H0032',
            {
                templateUrl: '/templates/report/H0032.html',
                controller: 'H0032AppointmentsCtrl'
            });
            $routeProvider.when('/report/psych',
                {
                    templateUrl: '/templates/report/psych.html',
                    controller: 'PsychCtrl'
                });
            //
            $routeProvider.when('/report/continuity-care',
            {
                templateUrl: '/templates/report/continuity-care.html',
                controller: 'ContinuityCareCtrl'
            });
            $routeProvider.when('/report/first_psych',
            {
                templateUrl: '/templates/report/first_psych.html',
                controller: 'FirstPsychCtrl'
            });
            $routeProvider.when('/report/first_intake',
            {
                templateUrl: '/templates/report/first_intake.html',
                controller: 'FirstIntakeCtrl'
            });
            $routeProvider.when('/report/intake',
                {
                    templateUrl: '/templates/report/intake.html',
                    controller: 'IntakeCtrl'
                });
            $routeProvider.when('/report/show_rate_all',
            {
                templateUrl:'/templates/report/no_show.html',
                controller: 'NoShowCtrl'
            });
            $routeProvider.when('/report/show_rate_second',
            {
                templateUrl:'/templates/report/no_show_second.html',
                controller: 'NoShowSecondCtrl'
            });
            $routeProvider.when('/report/clinical_report',
            {
                templateUrl:'/templates/report/clinical_report.html',
                controller: 'ClinicalReportCtrl'
            });
            $routeProvider.when('/report/all_appointments',
            {
                templateUrl:'/templates/report/all_appointments.html',
                controller: 'AllAppointmentsCtrl'
            });
            $routeProvider.when('/report/php',
            {
                templateUrl:'/templates/report/php.html',
                controller: 'PHPAppointmentsCtrl'
            });
            $routeProvider.when('/exceptions',
            {
                templateUrl:'/templates/report/exception.html',
                controller: 'ExceptionsReportCtrl'
            });
            $routeProvider.when('/volume/active',
            {
                templateUrl:'/templates/report/volume_data.html',
                controller: 'VolumeDataReportCtrl'
            });
            $routeProvider.when('/referrals',
                {
                    templateUrl:'/templates/report/referral_sources.html',
                    controller: 'ReferralSourcesCtrl'
                });
            $routeProvider.when('/health/vital',
                {
                    templateUrl:'/templates/report/vital_signs.html',
                    controller: 'VitalSignsCtrl'
                });
            $routeProvider.when('/charts/business',
                {
                    templateUrl:'/templates/charts/business.html',
                    controller: 'BusinessProcessQualityCtrl'
                });
            $routeProvider.when('/medical/received',
            {
                templateUrl:'/templates/report/medical_records_got.html',
                controller: 'MedicalRecordsGotCtrl'
            });
            $routeProvider.when('/health_info',
            {
                templateUrl:'/templates/report/health_tech.html',
                controller: 'HealthTechCtrl'
            });
            $routeProvider.when('/clinical/stay',
            {
                templateUrl:'/templates/report/clinical_stay.html',
                controller: 'ClinicalStayCtrl'
            });
            $routeProvider.when('/clinical/re-admits',
            {
                templateUrl:'/templates/report/clinical_readmits.html',
                controller: 'ClinicalReadmitsCtrl'
            });
            $routeProvider.when('/grouped-php',
            {
                templateUrl:'/templates/report/grouped-php.html',
                controller: 'GroupedPhpCtrl'
            });
            //End Reports


            //
            $routeProvider.when('/configure/users', {
                templateUrl: '/templates/configure/users.html',
                controller: 'ConfigureUsersCtrl'
            });

            $routeProvider.when('/logout', {
                controller: [
                    '$scope', 'api',
                    function ($scope, api)
                    {
                        api.revokeAuthentication()
                            .then(function ()
                            {
                                $scope.$emit('authenticated', false);
                            })
                            .catch(function (r)
                            {
                                console.log('error', r);
                            });
                    }
                ],
                template: '<div class="container"><div class="col-xs-12 text-center"><h1>Please Wait</h1><p>We are revoking your authentication.</p></div></div>'
            });

            $routeProvider.otherwise({
                controller: [
                    '$scope', 'alerts', "$location",
                    function ($scope, alerts, $location)
                    {
                        alerts
                            .error('Page Not Found', 'Something went wrong!!! You got to this page, ' +
                                'but I do not know how serve it to you. Please contact your support ' +
                                'person.',
                                function ()
                                {
                                    $location.path('/charts/revenue');
                                });
                    }
                ],
                template: '<div></div>'
            });

            $locationProvider.html5Mode(true);
        }
    ]).config([
        'ipnConfig',
        function (ipnConfig)
        {
            ipnConfig.autoFormat = true;
            ipnConfig.autoHideDialCode = false;
            ipnConfig.skipUtilScriptDownload = true;
        }
    ])
})();
