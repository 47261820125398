/**
 * .
 */
(function(app) {
    'use strict';
    app.controller('ChartIntakeCallCtrl', [
        '$scope', 'api', 'highchartsNG',
        function ($scope, api, highchartsNG)
        {
            $scope.series = {name:'Assigned',data:[]};

            $scope.get_data = function()
            {
                $scope.series.data.length = 0;
                api
                    .getIntakeCallsPerSite({dates:$scope.dates, provider:$scope.selected_provider})
                    .then(function(results)
                    {
                        angular.forEach(results.calls, function(value)
                        {
                            $scope.series.data.push([value.name, value.calls]);
                        },true);
                        $scope.chart_config.yAxis.max = 2000;
                    })
                    .catch(function(errors)
                    {

                    });
            };

            $scope.$watch('dates', function()
            {
                $scope.get_data();
            },true);
            $scope.$watch('selected_provider', function()
            {
                $scope.get_data();
            });

            highchartsNG.ready(function()
            {
                $scope.chart_config = {

                    options: {
                        chart: {
                            type: 'column'
                        },
                        title:{text:''},
                        tooltip: {
                            style: {
                                padding: 10,
                                fontWeight: 'bold'
                            }
                        }
                    },
                    xAxis: {
                        type: 'category',

                        labels: {
                            rotation: -45,
                            style: {
                                fontSize: '13px',
                                fontFamily: 'Verdana, sans-serif'
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        max:5000,
                        title: {
                            text: ''
                        }
                    },
                    //Whether to use Highstocks instead of Highcharts (optional). Defaults to false.
                    useHighStocks: false,
                    //size (optional) if left out the chart will default to size of the div or something sensible.
                    size: {
                        height: 300
                    },
                    series:[
                        $scope.series
                    ],
                    dataLabels: {
                        enabled: true,
                        rotation: -90,
                        color: '#FFFFFF',
                        align: 'right',
                        format: '{point.y:.1f}', // one decimal
                        y: 10, // 10 pixels down from the top
                        style: {
                            fontSize: '13px',
                        }
                    },
                    //function (optional)
                    func: function (chart) {
                        //setup some logic for the chart
                    }
                };
            })
        }]);
})(window.NewOaklandDashboard);
