'use strict';

(function (app) {
    app.controller('AppCtrl', [
        '$scope', '$location', '$uibModal', 'api', '$timeout',
        function ($scope, $location, $uibModal, api, $timeout) {
            $scope.format_date = function (date, format) {
                return moment(date).format(format);
            };
            $scope.is_authenticated = false;
            $scope.can_configure = false;

            $scope.original_path = $location.path();
            $scope.updated_date = '';

            $scope.menu = [{
                name: 'Dashboard',
                active: false,
                items: [
                    {
                        name: 'Revenue',
                        href: '/charts/revenue',
                        active: false
                    },
                    {
                        name: 'Site Assessment',
                        href: '/charts/site',
                        active: false
                    },
                    {
                        name: 'Business Process Quality',
                        href: '/charts/business',
                        active: false
                    },
                    {
                        name: 'Access to Care',
                        href: '/charts/care',
                        active: false
                    }
                ]

            },
                {
                    name: 'Reports',
                    active: false,
                    items: [
                        {
                            name: "Active Patients",
                            href: "/volume/active",
                            active: false
                        },
                        {
                            name: 'All Appointments',
                            href: '/report/all_appointments',
                            active: false
                        },
                        {
                            name: 'All Intake Appointments',
                            href: '/report/intake',
                            active: false
                        },
                        {
                            name: 'All Psychiatric Appointments',
                            href: '/report/psych',
                            active: false
                        },
                        {
                            name: 'All Therapy Appointments',
                            href: '/report/therapy',
                            active: false
                        },
                        {
                            name: 'Call Intake',
                            href: '/report/calls',
                            active: false
                        },
                        {
                            name: 'Charges',
                            href: '/report/charges',
                            active: false
                        },
                        {
                            name: 'Clients',
                            href: '/report/clients',
                            active: false
                        },
                        {
                            name: 'Clinical Resource Report',
                            href: '/report/clinical_report',
                            active: false
                        },
                        {
                            name: 'Current Kept Intake Appointments',
                            href: '/report/first_intake',
                            active: false
                        },
                        {
                            name: 'Current Kept Psychiatric Appointments',
                            href: '/report/first_psych',
                            active: false
                        },
                        {
                            name: 'Current Kept Therapy Appointments',
                            href: '/report/first_therapy',
                            active: false
                        },
                        {
                         name: "Continuity Care",
                         href: "/report/continuity-care",
                         active: false
                         },
                        {
                            name: 'Exceptions',
                            href: '/exceptions',
                            active: false
                        },
                        {
                            name: 'Grouped PHP',
                            href:'/grouped-php',
                            active:false
                        },
                       {
                            name: 'Health Information Technology',
                            href: '/health_info',
                            active: false
                        },
                        {
                            name: 'H0032 Appointments',
                            href: '/report/H0032',
                            active: false
                        },

                        {
                            name: 'No Show Rate - All Appointments',
                            href: '/report/show_rate_all',
                            active: false
                        },
                        {
                            name: 'No Show Rate - Second Appointments',
                            href: '/report/show_rate_second',
                            active: false
                        },
                        {
                            name: "Outpatient Length of Stay",
                            href: "/clinical/stay",
                            active: false
                        },
                        {
                            name: 'PHP Appointments',
                            href: '/report/php',
                            active: false
                        },
                        {
                            name: "PHP Re-admits Within 30 Days",
                            href: "/clinical/re-admits",
                            active: false
                        },
                        {
                            name: 'Referrals',
                            href: '/referrals',
                            active: false
                        },
                        {
                            name: 'Revenue',
                            href: '/report/revenue',
                            active: false
                        },


                        {
                            name: "Vital Signs",
                            href: "/health/vital",
                            active: false
                        }

                    ]
                },
               /* {
                    name: "Outpatient Length of Stay",
                    href: "/clinical/stay",
                    active: false
                },
                {
                    name: "PHP Re-admits Within 30 Days",
                    href: "/clinical/re-admits",
                    active: false
                },
                // {*/
                //     name: "Clinical Quality",
                //     href: "#",
                //     items: [
                //         {
                //             name: "Outpatient Length of Stay",
                //             href: "/clinical/stay"
                //         },
                //         {
                //             name: "PHP Re-admits Within 30 Days",
                //             href: "/clinical/re-admits"
                //         }
                //     ]
                // },
                // {
                //     name: "Access to Care",
                //     href: "#",
                //     items: [
                //         {
                //             name: "Call Center to Intake",
                //             href: "access/call_to_intake"
                //         },
                //         {
                //             name: "Intake to Therapy",
                //             href: "access/intake_to_therapy"
                //         },
                //         {
                //             name: "Intake to Psychiatric",
                //             href: "access/intake_to_psych"
                //         }
                //     ]
                // },
                // {
                //     name: "Business Process Quality",
                //     href: "#",
                //     items: [
                //         {
                //             name: "Appointments Scheduled",
                //             href: "/business/scheduled",
                //             active: false
                //         },
                //         {
                //             name: "Appointments Attended",
                //             href: "/business/attended",
                //             active: false
                //         },
                //         {
                //             name: "New Patients",
                //             href: "/business/new_patients",
                //             active: false
                //         },
                //         {
                //             name: "Active OP",
                //             href: "/business/op",
                //             active: false
                //         },
                //         {
                //             name: "Active PHP",
                //             href: "/business/PHP",
                //             active: false
                //         }
                //    ]
                //}
            ];

            $scope.all_menus = [];
            $scope.completed_status = null;
            $scope.loading_status = false;
            $scope.check_for_complete = function () {
                $scope.loading_status = true;
                api.getCompletePercent()
                    .then(function (results) {
                        $scope.completed_status = results.results;
                        if ($scope.completed_status != null)
                            $scope.completed_status.time = moment($scope.completed_status.time).format('MMM Do, YYYY h:mm:ss a');

                        $timeout(function () {
                            $scope.check_for_complete()
                        }, 30000);
                        $scope.loading_status = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        $scope.completed_status = null;
                        $scope.loading_status = false;
                    });
            };

            (function () {
                for (var i = 0; i < $scope.menu.length; i++) {
                    $scope.all_menus.push($scope.menu[i]);
                    if ($scope.menu[i].items != null && $scope.menu[i].items.length)
                        _dig($scope.menu[i].items, 1);
                }
                $scope.check_for_complete();
            })();

            function _dig(array, depth) {
                for (var i = 0; i < array.length; i++) {
                    if (depth >= 2 && i == array.length - 1) {
                        array[i].bottom = true;
                    }
                    $scope.all_menus.push(array[i]);
                    if (array[i].items != null && array[i].items.length)
                        _dig(array[i].items, 2);
                }
            }

            $scope.$watch('can_configure', function (can_configure) {
                $scope.menu
                    .filter(function (a) {
                        return a.href == 'configure/users'
                    })
                    .forEach(function (a) {
                        a.disabled = !can_configure;
                    });
            });

            $scope.$on('$locationChangeSuccess', function (event, location) {
                for (var i in $scope.menu) {
                    var item = $scope.menu[i];

                    var reg = new RegExp(item.href + '$');

                    if (reg.test(location)) {
                        item.active = true;
                    }
                    else {
                        item.active = false;

                        if (item.hasOwnProperty('items')) {
                            for (var ii in item.items) {
                                var item2 = item.items[ii];
                                var reg2 = new RegExp(item2.href + '$');

                                if (reg2.test(location)) {
                                    item.active = true;
                                }
                            }
                        }
                    }
                }
            });

            $scope.$on('authenticated', function (ev, authenticated) {
                $scope.is_authenticated = authenticated;

                if (authenticated) {
                    api
                        .getCurrentUser()
                        .then(function (result) {
                            if ($scope.original_path == '/login') {
                                $scope.original_path = '/charts/revenue';
                            }
                            api.getUpdateDate()
                                .then(function (results) {
                                    $scope.updated_date = moment(results.date).format('MMMM Do YYYY, h:mm:ss a');
                                })
                                .catch(function (error) {
                                    console.log(error);
                                });
                            $scope.current_user = result.user;

                        })
                        .catch(function (error) {
                            alerts.error('Issue Grabbing User Information', error);
                            $scope.$emit('authenticated', false);
                        });

                    $location.path($scope.original_path).replace();
                }
                else {
                    $location.path('/login');
                }
            });


            $scope.addCustomer = function (callback) {
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/customer/add.html',
                    controller: 'CustomerAddCtrl',
                    backdrop: 'static',
                    size: 'xl'
                });

                modalInstance.result.then(function (customer_id) {
                    callback(true, customer_id);
                }, function () {
                    callback(false);
                });
            };

            $location.path('/login').replace();
        }
    ]);
})(window.NewOaklandDashboard);
