'use strict';


(function (app)
{

    app.factory('alerts', [
        '$uibModal',
        'SweetAlert',
        function ($uibModal,SweetAlert)
        {
            var alerts = {};

            alerts.error = function(title, message, callback)
            {
                
                /*
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/error.html',
                    controller: 'AlertsCtrl',
                    resolve: {
                        message: function() { return message; },
                        title: function() { return title; }
                    }
                });

                modalInstance.result.then(callback, callback);
                */

                SweetAlert.swal(
                    {
                        type:'error',
                        title:title,
                        text:message
                    },
                    function(confirm)
                    {
                        if(confirm && callback != null)
                            callback();
                    }
                );
            };

            alerts.success = function (title, message, callback)
            {
                var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/success.html',
                    controller: 'AlertsCtrl',
                    resolve: {
                        message: function() { return message; },
                        title: function() { return title; }
                    }
                });

                modalInstance.result.then(callback, callback);
            };

            alerts.confirm = function (title, message, yes_callback, no_callback)
            {
                SweetAlert.swal(
                    {
                        type:'warning',
                        title:title,
                        text:message,
                        showCancelButton:true,
                    },
                    function(confirm)
                    {
                        if(confirm && yes_callback != null)
                        {
                            yes_callback();
                        }
                        else
                        {
                            if(no_callback != null)
                                no_callback()
                        }
                    }
                );
                /*var modalInstance = $uibModal.open({
                    templateUrl: '/templates/alerts/confirm.html',
                    controller: 'AlertsCtrl',
                    resolve: {
                        message: function() { return message; },
                        title: function() { return title; }
                    }
                });

                modalInstance.result.then(yes_callback, no_callback);*/
            };

            return alerts;
        }
    ]);


})(window.NewOaklandDashboard);