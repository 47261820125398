'use strict';


(function (app)
{
    app.controller('AlertsCtrl', [
        '$scope', '$uibModalInstance', 'title', 'message',
        function ($scope, $uibModalInstance, title, message)
        {
            $scope.title = title;
            $scope.message = message;

            $scope.close = function(r)
            {
                $uibModalInstance.close(r);
            }

            $scope.dismiss = function(r)
            {
                $uibModalInstance.dismiss(r)
            }
        }
    ]);
})(window.NewOaklandDashboard);