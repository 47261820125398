(function (app)
{
    'use strict';

    app.controller('ReportClientsCtrl', [
        '$scope', 'NgTableParams', 'api', '$httpParamSerializer', '$timeout', 'alerts',
        function ($scope, NgTableParams, api, $httpParamSerializer, $timeout, alerts)
        {
            $scope.is_loading = false;
            $scope.get_data_error = '';
            $scope.filters = {};
            $scope.total = 0;

            $scope.doFilter = function ()
            {
                $scope.tableParams.reload();
            };

            $scope.getExport = function ()
            {
                var url = $scope.tableParams.url();

                for (var i in $scope.filters)
                {
                    if ($scope.filters.hasOwnProperty(i))
                    {
                        var value = $scope.filters[i];

                        if (value !== '')
                        {
                            url['filter[' + i +']'] = value;
                        }
                    }
                };

                if ($scope.tableParams.total() > 10000)
                {
                    alerts.confirm(
                        'Warning',
                        'There are more than 10000 records in this export. It may take a long to generate. Are you sure you want to continue?' +
                        ' It is recommended that you filter the results reduce to the number of records.',
                        function (){ window.open('/api/data/all-clients?export=1&' + $httpParamSerializer(url)); },
                        function (){}
                    );
                }
                else
                {
                    window.open('/api/data/all-clients?export=1&' + $httpParamSerializer(url));
                }
            };

            $scope.tableParams = new NgTableParams({
                sorting: { 'client_id': 'desc' },
                count: 10
            }, {
                paginationMaxBlocks: 8,
                counts:[],
                getData: function (params)
                {
                    $scope.is_loading = true;
                    $scope.get_data_error = '';
                    $scope.totals = {};

                    var url = params.url();

                    for (var i in $scope.filters)
                    {
                        if ($scope.filters.hasOwnProperty(i))
                        {
                            var value = $scope.filters[i];

                            if (value !== '')
                            {
                                url['filter[' + i +']'] = value;
                            }
                        }
                    }

                    $scope.last_req = api
                        .clientsReport(url)
                        .then(function (data){
                            $scope.is_loading = false;
                            params.total(data.total);
                            $scope.total = data.total;

                            return data.records;
                        }).catch(function (error)
                        {
                            $scope.totals = {};
                            $scope.is_loading = false;
                            $scope.get_data_error = 'Failed to get data, please check your filters.';
                            console.log(error);
                        });

                    return $scope.last_req;
                }
            });
        }
    ]);
})(window.NewOaklandDashboard);

