'use strict';

(function (app)
{
    app.factory('objectUrl', [
        function ()
        {
            return {
                serialize: function (obj)
                {
                    try
                    {
                        return btoa(angular.toJson(obj)).replace(/=/g, '_');
                    } catch (r)
                    {
                        return null;
                    }
                },
                deserialize: function (hash)
                {
                    if (!hash)
                    {
                        return hash;
                    }
                    try
                    {
                        return angular.fromJson(atob(hash.replace(/_/g, '=')));
                    } catch (e)
                    {
                        return null;
                    }
                }
            };
        }
    ]);

})(window.NewOaklandDashboard);