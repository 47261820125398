'use strict';


(function (app)
{

    app.factory('api', [
        '$rootScope', '$http', '$location', '$q', 'alerts',
        function ($rootScope, $http, $location, $q, alerts)
        {
            var rootpoint = '/api';
            var apiFactory = {};

            apiFactory.isAuthenticated = function ()
            {
                return doRequest('auth', 'authenticated', 'get');
            };

            apiFactory.sendAuthentication = function (username, password)
            {
                return doRequest('auth', 'authenticate', 'post', {
                    username: username,
                    password: password
                });
            };

            apiFactory.revokeAuthentication = function ()
            {
                return doRequest('auth', 'revoke', 'get');
            };

            //Data
            apiFactory.getAllStatuses = function()
            {
                return doRequest('data', 'all-statuses', 'get');
            };
            apiFactory.getProviders = function()
            {
                return doRequest('data','providers', 'get');
            };
            apiFactory.getIntakeCalls = function(data)
            {
                return doRequest('data','intake-calls', 'get', data);
            };
            apiFactory.getIntakeCallsPerSite = function(data)
            {
                return doRequest('data', 'intake-calls-per-site', 'get', data);
            };
            //Psych
            apiFactory.getFirstPsychAppointment = function(data)
            {
                return doRequest('data', 'first-psych-appointment', 'get', data);
            };
            apiFactory.getAllPsychAppointment = function(data)
            {
                return doRequest('data', 'all-psych-appointment', 'get', data);
            };
            //---
            apiFactory.getH0032Appointments = function(data)
            {
                return doRequest('data', 'h0032-appointments', 'get', data);
            };
            //Therapy
            apiFactory.getTherapyAppointment = function(data)
            {
                return doRequest('data','therapy-appointment', 'get', data);
            };
            apiFactory.getFirstTherapyAppointment = function(data)
            {
                return doRequest('data','first-therapy-appointment', 'get', data);
            };
            //----
            apiFactory.getFirstIntakeAppointment = function(data)
            {
                return doRequest('data', 'first-intake-appointment', 'get', data);
            };
            apiFactory.getIntakeAppointments = function(data)
            {
                return doRequest('data', 'intake-appointments', 'get', data);
            };
            apiFactory.getAppointmentShowUpRate = function(data)
            {
                return doRequest('data', 'appointment-show-up-rate', 'get', data);
            };
            apiFactory.getFirstAppointmentAfterIntake = function(data)
            {
                return doRequest('data', 'first-appointment-after-intake', 'get', data);
            };
            apiFactory.getClinicalResourceData = function(data)
            {
                return doRequest('data', 'clinical-resource-data', 'get', data);
            };
            apiFactory.getUpdateDate = function()
            {
                return doRequest('data', 'update-date', 'get', []);
            };
            apiFactory.getCompletePercent = function()
            {
                return doRequest('data', 'current-completion-time', 'get', []);
            };
            apiFactory.getAllAppointments = function(data)
            {
                return doRequest('data', 'all-appointments', 'get', data);
            };
            apiFactory.getAllPhpAppointments = function(data)
            {
                return doRequest('data', 'all-php-appointments', 'get', data);
            };
            //
            apiFactory.getCurrentUser = function()
            {
                return doRequest('user', 'current-user', 'get');
            };

            apiFactory.singleUser = function (id)
            {
                return doRequest('user', 'single', 'get', {
                    id: id
                });
            };

            apiFactory.listUsers = function ()
            {
                return doRequest('user', 'list', 'get');
            };

            apiFactory.createUsers = function (name, email, password, confirm, permission)
            {
                return doRequest('user', 'add', 'post', {
                    name: name,
                    email: email,
                    password: password,
                    confirm: confirm,
                    admin_type: permission
                });
            };

            apiFactory.removeUsers = function (user_id)
            {
                return doRequest('user', 'remove', 'get', {
                    id: user_id
                });
            };

            apiFactory.getOpenVsClosedData = function (timeframe, type)
            {
                return doRequest('data', 'open-vs-closed', 'get', {
                    timeframe: timeframe,
                    type: type
                });
            };

            apiFactory.getAgeData = function (timeframe, type)
            {
                return doRequest('data', 'age', 'get', {
                    timeframe: timeframe,
                    type: type
                });
            };

            apiFactory.getZipData = function (timeframe, type)
            {
                return doRequest('data', 'zip', 'get', {
                    timeframe: timeframe,
                    type: type
                });
            };

            apiFactory.getTypeData = function (timeframe, type)
            {
                return doRequest('data', 'type', 'get', {
                    timeframe: timeframe,
                    type: type
                });
            };

            apiFactory.getStaffData = function (timeframe, type)
            {
                return doRequest('data', 'staff', 'get', {
                    timeframe: timeframe,
                    type: type
                });
            };

            apiFactory.revenueReport = function (params)
            {
                return doRequest('data', 'all-revenue', 'get', params);
            };

            apiFactory.revenueTotals = function (params)
            {
                return doRequest('data', 'revenue-totals', 'get', params);
            };

            apiFactory.chargesReport = function (params)
            {
                return doRequest('data', 'all-charges', 'get', params);
            };

            apiFactory.chargesTotals = function (params)
            {
                return doRequest('data', 'charges-totals', 'get', params);
            };

            apiFactory.clientsReport = function (params)
            {
                return doRequest('data', 'all-clients', 'get', params);
            };
            apiFactory.exceptionReport = function (data)
            {
                return doRequest('exception', 'exception-report', 'get', data);
            };
            apiFactory.clinicalResourceReport = function(data)
            {
                return doRequest('pdf','clinical-resource-report', 'post', data);
            };
            apiFactory.volumeData = function(data)
            {
                return doRequest('data', 'volume-data', 'get', data);
            };
            apiFactory.referralSources = function(data)
            {
                return doRequest('data', 'referral-source', 'get', data);
            };
            apiFactory.vitalSigns = function(data)
            {
                return doRequest('data', 'vital-signs', 'get', data);
            };
            apiFactory.clinicalQualityStay = function(data)
            {
                return doRequest('data', 'clinical-quality-stay', 'get', data)
            };
            apiFactory.clinicalQualityReadmit = function(data)
            {
                return doRequest('data', 'clinical-quality-readmit', 'get', data)
            };
            apiFactory.businessQuality = function(data)
            {
                return doRequest('data', 'business-quality', 'get', data);
            };
            apiFactory.AccessToCare = function(data)
            {
                return doRequest('data', 'care-data', 'get', data);
            };
            apiFactory.healthTech = function(data)
            {
                return doRequest('data', 'health-tech', 'get', data);
            };
            apiFactory.getMedicalRecordGot = function (data)
            {
                return doRequest('data', 'medical-record-got', 'get', data)
            };
            apiFactory.getContinuityCare = function(data)
            {
                return doRequest('data', 'continuity-care', 'get', data);
            };
            apiFactory.getGroupedPhp = function(data)
            {
                return doRequest('data', 'grouped-php', 'get', data);
            };
            return apiFactory;

            function doRequest(midpoint, endpoint, method, data)
            {
                var url = (midpoint != "pdf" ? rootpoint : "") + '/' + midpoint + '/' + endpoint;
                var cancel_q = $q.defer();

                var q = $q(function (resolve, reject)
                {
                    switch (method)
                    {
                        case 'get':
                            return $http.get(url, {
                                params: data,
                                timeout: cancel_q.promise
                            }).then(function (data, status, headers)
                            {
                                onValid.call(this, data.data);
                                resolve.call(this, data.data.data);
                            }).catch(function (data)
                            {
                                onError.call(this, data);
                                reject.call(this, data.data.error);
                            });
                            break;
                        case 'post':
                            return $http.post(url, data, {timeout: cancel_q.promise}).then(function (data, status, headers)
                            {
                                onValid.call(this, data.data);
                                resolve.call(this, data.data.data);
                            }).catch(function (data)
                            {

                                onError.call(this, data);
                                reject.call(this, data.data.error);
                            });
                            break;
                    }
                });

                q['cancel'] = function (reason)
                {
                    cancel_q.resolve(reason);
                };

                return q;
            }

            function doRequestFile(midpoint, endpoint, file, type)
            {
                var url = rootpoint + '/' + midpoint + '/' + endpoint;

                var fd = new FormData();
                fd.append('file', file);
                if (type)
                {
                    fd.append('type', type);
                }

                return $q(function (resolve, reject)
                {
                    $http.post(url, fd, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined}
                    }).then(function (data, status, headers)
                    {
                        onValid.call(this, data.data);
                        resolve.call(this, data.data.data);
                    }).catch(function (data)
                    {
                        onError.call(this, data);
                        reject.call(this, data.data.error);
                    });
                });
            }

            function onValid(data)
            {
                if (data.hasOwnProperty('immediate_action') && data.immediate_action)
                {
                    console.log(data.immediate_action);
                }
            }

            function onError(data)
            {

                if (data.status == 401)
                {
                    $rootScope.$broadcast('authenticated', false);
                    //$location.path('/login');
                }
            }

        }
    ]);


})(window.NewOaklandDashboard);
