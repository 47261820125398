(function (app) {
    'use strict';

    app
        .directive('toggleNavCollapsedMin', ['$rootScope', toggleNavCollapsedMin])
        .directive('collapseNav', collapseNav)
        .directive('highlightActive', highlightActive)
        .directive('toggleOffCanvas', toggleOffCanvas)
        .directive('sidebarToggle',['$rootScope', sidebarToggle])
        .directive('sidebarButton',['$rootScope', sidebarButton]);


    function sidebarButton($rootScope) {
        return {
            restrict: 'A',
            link: link
        };
        function link(scope, element, attrs) {
            element.bind('click', function()
            {
                if(window.innerWidth < 768)
                {
                    var sidebar = $('#sidebar-wrapper');
                    var wrapper = $('#wrapper');
                    var icon = $('#nav_btn').children('span');
                    if(sidebar.is(':visible'))
                    {
                        $rootScope.active_sidebar = false;
                        sidebar.css('width', '0px');
                        wrapper.css('padding-left', '0px');
                        icon.removeClass('glyphicon-menu-left');
                        icon.addClass('glyphicon-menu-right');
                    }
                }
            });
        }
    }
        function sidebarToggle($rootScope)
    {
        return {
            restrict:'A',
            link: link
        };
        function link(scope, element, attrs)
        {
            $rootScope.active_sidebar = false;
            $(document).ready(function()
            {
                var icon = element.children('span');

                if(window.innerWidth <= 768)
                {
                    icon.removeClass('glyphicon glyphicon-menu-left');
                    icon.addClass('glyphicon glyphicon-menu-right');
                }
                else
                {
                    icon.removeClass('glyphicon glyphicon-menu-right');
                    icon.addClass('glyphicon glyphicon-menu-left');
                }
            });
            $(window).resize(function()
            {
                if($rootScope.active_sidebar)
                    return;
                var sidebar = $('#sidebar-wrapper');
                var wrapper = $('#wrapper');
                var icon = element.children('span');

                if(window.innerWidth <= 768)
                {
                    sidebar.css('width', '0px');
                    wrapper.css('padding-left', '0px');
                    icon.removeClass('glyphicon glyphicon-menu-left');
                    icon.addClass('glyphicon glyphicon-menu-right');

                }
                else
                {
                    wrapper.css('padding-left', '120px');
                    sidebar.css('width', '120px');
                    icon.removeClass('glyphicon glyphicon-menu-right');
                    icon.addClass('glyphicon glyphicon-menu-left');
                }
            });
            element.on('click', function()
            {
                var sidebar = $('#sidebar-wrapper');
                var wrapper = $('#wrapper');
                var icon = element.children('span');
                if(sidebar.width() > 0)
                {
                    sidebar.css('width', '0px');
                    wrapper.css('padding-left', '0px');
                    icon.removeClass('glyphicon glyphicon-menu-left');
                    icon.addClass('glyphicon glyphicon-menu-right');
                    $rootScope.active_sidebar = false;
                }
                else
                {
                    wrapper.css('padding-left', '120px');
                    sidebar.css('width', '120px');
                    icon.removeClass('glyphicon glyphicon-menu-right');
                    icon.addClass('glyphicon glyphicon-menu-left');
                    $rootScope.active_sidebar = true;
                }

            });
        }
    }

    // switch for mini style NAV, realted to 'collapseNav' directive
    function toggleNavCollapsedMin($rootScope) {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, ele, attrs) {
            var app;

            app = $('#app');

            ele.on('click', function(e) {
                console.log('click');
                if (app.hasClass('nav-collapsed-min')) {
                    app.removeClass('nav-collapsed-min');
                } else {
                    app.addClass('nav-collapsed-min');
                    $rootScope.$broadcast('nav:reset');
                }
                return e.preventDefault();
            });
        }
    }

    // for accordion/collapse style NAV
    function collapseNav() {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, ele, attrs) {
            var $a, $aRest, $app, $lists, $listsRest, $nav, $window, Timer, prevWidth, slideTime, updateClass;

            slideTime = 250;

            $window = $(window);

            $lists = ele.find('ul').parent('li');

            $lists.append('<i class="fa fa-angle-down icon-has-ul-h"></i>');

            $a = $lists.children('a');
            $a.append('<i class="fa fa-angle-down icon-has-ul"></i>');

            $listsRest = ele.children('li').not($lists);

            $aRest = $listsRest.children('a');

            $app = $('#app');

            $nav = $('#nav-container');

            $a.on('click', function(event) {
                var $parent, $this;
                if ($app.hasClass('nav-collapsed-min') || ($nav.hasClass('nav-horizontal') && $window.width() >= 768)) {
                    return false;
                }
                $this = $(this);
                $parent = $this.parent('li');
                $lists.not($parent).removeClass('open').find('ul').slideUp(slideTime);
                $parent.toggleClass('open').find('ul').stop().slideToggle(slideTime);
                event.preventDefault();
            });

            $aRest.on('click', function(event) {
                $lists.removeClass('open').find('ul').slideUp(slideTime);
            });

            scope.$on('nav:reset', function(event) {
                $lists.removeClass('open').find('ul').slideUp(slideTime);
            });

            Timer = void 0;

            prevWidth = $window.width();

            updateClass = function() {
                var currentWidth;
                currentWidth = $window.width();
                if (currentWidth < 768) {
                    $app.removeClass('nav-collapsed-min');
                }
                if (prevWidth < 768 && currentWidth >= 768 && $nav.hasClass('nav-horizontal')) {
                    $lists.removeClass('open').find('ul').slideUp(slideTime);
                }
                prevWidth = currentWidth;
            };

            $window.resize(function() {
                var t;
                clearTimeout(t);
                t = setTimeout(updateClass, 300);
            });

        }
    }

    // Add 'active' class to li based on url, muli-level supported, jquery free
    function highlightActive() {
        var directive = {
            restrict: 'A',
            controller: [ '$scope', '$element', '$attrs', '$location', toggleNavCollapsedMinCtrl]
        };

        return directive;

        function toggleNavCollapsedMinCtrl($scope, $element, $attrs, $location) {
            var highlightActive, links, path;

            links = $element.find('a');

            path = function() {
                return $location.path();
            };

            highlightActive = function(links, path) {
                path = '#' + path;
                return angular.forEach(links, function(link) {
                    var $li, $link, href;
                    $link = angular.element(link);
                    $li = $link.parent('li');
                    href = $link.attr('href');
                    if ($li.hasClass('active')) {
                        $li.removeClass('active');
                    }
                    if (path.indexOf(href) === 0) {
                        return $li.addClass('active');
                    }
                });
            };

            highlightActive(links, $location.path());

            $scope.$watch(path, function(newVal, oldVal) {
                if (newVal === oldVal) {
                    return;
                }
                return highlightActive(links, $location.path());
            });

        }

    }

    // toggle on-canvas for small screen, with CSS
    function toggleOffCanvas() {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, ele, attrs) {
            ele.on('click', function() {
                return $('#app').toggleClass('on-canvas');
            });
        }
    }


})(window.NewOaklandDashboard);
