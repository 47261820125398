(function (app)
{
    'use strict';

    app.controller('SiteChartsCtrl', [
        '$scope','alerts','api','$location',
        function ($scope,alerts,api,$location)
        {
            $scope.dates =
            {
                from:new Date(moment().subtract(2,'week').format('MM/DD/YYYY')),
                thru:new Date(moment().format('MM/DD/YYYY'))
            };
            $scope.selected_provider = "";
            $scope.providers = [
                {provider_id:"", provider_full_name:'All Providers'}
            ];
            (function()
            {
                api
                    .getProviders()
                    .then(function(results)
                    {
                        angular.forEach(results.providers, function(value)
                        {
                            $scope.providers.push(value);
                        });
                        
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    })
            })();


            $scope.widgets = [
                '/templates/charts/site/chart_intake_calls_per.html'
            ];


            $scope.openReport = function(report)
            {
                $location.search({
                    from:$scope.dates.from,
                    thru:$scope.dates.thru,
                    provider:$scope.selected_provider
                });
                $location.path(report);
            }
        }
    ]);
})(window.NewOaklandDashboard);
