'use strict';


(function (app)
{
    app.filter('telephone',
        function ()
        {
            return function (raw, simple)
            {
                if (!raw)
                {
                    return '';
                }

                var phone_split = raw.split('x');

                var phone = phone_split[0];
                var extension = '';

                if (phone_split.length == 2)
                {
                    extension = ' x' + phone_split[1];
                }

                var value = phone.toString().trim().replace(/^\+/, '');

                if (value.length == 11)
                {
                    value = value.replace(/^1/, '');
                }

                var formatted = intlTelInputUtils.formatNumber(value, 'us', intlTelInputUtils.numberFormat.INTERNATIONAL)

                if (simple === true)
                {
                    return formatted + extension;
                }
                else
                {
                    return '<a href="tel:' + value + '">' + formatted + extension + '</a>';
                }

            }
        }
    );


})(window.NewOaklandDashboard);