/**
 * .
 */
(function(app)
{
    app.controller('ClinicalReportCtrl',[
        '$scope','api', 'alerts', '$httpParamSerializer',
        function($scope, api, alerts, $httpParamSerializer)
        {
            $scope.date = {from:new Date(moment().subtract(1,'month').format('MM/DD/YYYY')),thru:new Date(moment().format('MM/DD/YYYY'))};
            $scope.data = {};
            $scope.is_loading = false;
            $scope.data_loaded = false;
            $scope.$watch('date', function(){ $scope.data_loaded = false;}, true);
            $scope.pretty_date = function()
            {
                return {
                    from:moment($scope.date.from).format('MMMM, Do'),
                    thru:moment($scope.date.thru).format('MMMM, Do, YYYY')
                }
            };
            
            $scope.getCallsData = function()
            {
                $scope.is_loading = true;
                api.getClinicalResourceData({date:$scope.date, all:1})
                .then(function(results)
                {



                    var total_intake = 0;

                    var total_intake_attended = 0;

                    var total_therapy = 0;
                    var total_therapy_attended = 0;

                    var total_psych = 0;
                    var total_psych_attended = 0;

                    var total_givin_therapy = 0;
                    var total_consumers = 0;

                    for(var i = 0; i < results.records.length; i++)
                    {
                        var site = results.records[i];

                        total_intake += site.op;
                        total_intake_attended += site.attended_op;

                        total_therapy += site.givin_th;
                        total_therapy_attended += site.kept_th;

                        total_psych += site.givin_pe;
                        total_psych_attended += site.kept_pe;

                        total_givin_therapy += site.givin_th;
                        total_consumers += site.consumers;

                        site.total_php = Math.round(site.php / (site.php + site.attended_php)) * 100;

                        results.records[i] = site;

                    }

                    $scope.sites_data = results.records;
                    $scope.big_picture_intake = Math.round((total_intake_attended / total_intake) * 100);
                    $scope.big_picture_therapy = Math.round((total_therapy_attended / total_therapy) * 100);;
                    $scope.big_picture_psych = Math.round((total_psych_attended / total_psych) * 100);;
                    $scope.big_picture_intake_therapy = Math.round((total_givin_therapy / total_consumers) * 100);;

                    $scope.data.total_calls = results.total;
                    $scope.data.relevant_calls = results.relevant;
                    $scope.unknown = results.unknown;
                    $scope.is_loading = false;
                    $scope.data_loaded = true;




                    $scope.all_data = {
                        date:$scope.pretty_date(),
                        big_picture_intake:$scope.big_picture_intake,
                        big_picture_psych:$scope.big_picture_psych,
                        big_picture_therapy:$scope.big_picture_therapy,
                        big_picture_intake_therapy:$scope.big_picture_intake_therapy,
                        data:$scope.data,
                        unknown:$scope.unknown.length,
                        sites_data:$scope.sites_data
                    };

                })
                .catch(function(error)
                {
                    alerts.error('Error', error);
                    $scope.is_loading = false;
                })
            };
            $scope.percentTotal = function(n, total)
            {
                if(n == 0 || total == 0)
                    return 0;
                return Math.round((n / total) * 100);
            };
            $scope.getExport = function()
            {
                //window.open('/pdf/clinical-resource-report/?' + $httpParamSerializer(JSON.stringify($scope.all_data)));
                /*
                api.clinicalResourceReport($scope.all_data)
                    .then(function(r)
                    {
                        window.open(r.url);
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
                */
                html2canvas(document.getElementById('data_export'), {
                    onrendered: function (canvas) {

                        var data = canvas.toDataURL("image/jpeg", 1.0);


                        var docDef = {
                            content: [
                                {
                                    image: data,
                                    width: 500
                                }
                            ]
                        };
                        pdfMake.createPdf(docDef).download("Clinical_Report.pdf");
                    }
                });

            };
        }
    ])
})(window.NewOaklandDashboard);