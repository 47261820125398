(function (app)
{
    'use strict';

    app.controller('IncomingCallsCtrl', [
        '$scope', 'NgTableParams', 'api', '$httpParamSerializer', '$timeout', 'alerts','$location',
        function ($scope, NgTableParams, api, $httpParamSerializer, $timeout, alerts,$location)
        {
            $scope.is_loading = false;
            $scope.get_data_error = '';
            $scope.filters = {};
            $scope.date = {from:new Date(moment().subtract(1,'month').format('MM/DD/YYYY')),thru:new Date(moment().format('MM/DD/YYYY'))};
            $scope.totals = {};
            $scope.providers = [
                {
                    id:'',
                    title:'All Providers'
                },
                {
                    id:"null",
                    title:'Not Assigned a Provider'
                }
            ];
            $scope.disposition = "";
            $scope.age_group = "";

            (function()
            {
                $scope.search_params = $location.search();

                if($scope.search_params.from)
                {
                    $scope.date.from = new Date(moment($scope.search_params.from).format('MM/DD/YYYY'));
                    $scope.date.thru = new Date(moment($scope.search_params.thru).format('MM/DD/YYYY'));
                    $scope.selected_provider = $scope.search_params.provider;
                }
                api
                    .getProviders()
                    .then(function(results)
                    {

                        for(var i = 0; i < results.providers.length; i++)
                        {
                            var provider = results.providers[i];
                            $scope.providers.push(
                                {
                                    id: provider.provider_id,
                                    title: provider.provider_short_name,
                                    selected:$scope.selected_provider && $scope.selected_provider == provider.provider_id ? true : false
                                }
                            )
                        }

                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    });
            })();

            $scope.$watch('date', function()
            {
                $scope.tableParams.reload();

            },true);
            $scope.$watch('disposition', function()
            {
                $scope.tableParams.reload();
            });
            $scope.$watch('age_group', function()
            {
                $scope.tableParams.reload();
            });
            $scope.$watch('selected_provider', function()
            {
                $scope.tableParams.reload();
            });

            $scope.doFilter = function ()
            {
                $scope.tableParams.reload();
            };

            $scope.getExport = function ()
            {
                var url = $scope.tableParams.url();

                for (var i in $scope.filters)
                {
                    if ($scope.filters.hasOwnProperty(i))
                    {
                        var value = $scope.filters[i];

                        if (value !== '')
                        {
                            url['filter[' + i +']'] = value;
                        }
                    }
                };

                if ($scope.tableParams.total() > 10000)
                {
                    alerts.confirm(
                        'Warning',
                        'There are more than 10000 records in this export. It may take a long to generate. Are you sure you want to continue?' +
                        ' It is recommended that you filter the results reduce to the number of records.',
                        function (){
                            window.open('/api/data/intake-calls?export=1&' + $httpParamSerializer(
                                    {
                                        export:1,
                                        count:$scope.tableParams.count(),
                                        page:$scope.tableParams.page(),
                                        filter:$scope.tableParams.filter(),
                                        sorting:$scope.tableParams.sorting(),
                                        date:$scope.date,
                                        disposition:$scope.disposition,
                                        age_group:$scope.age_group,
                                        provider:$scope.selected_provider
                                    }
                                )
                            ); },
                        function (){}
                    );
                }
                else
                {
                    window.open('/api/data/intake-calls?export=1&' + $httpParamSerializer(
                            {
                                export:1,
                                count:$scope.tableParams.count(),
                                page:$scope.tableParams.page(),
                                filter:$scope.tableParams.filter(),
                                sorting:$scope.tableParams.sorting(),
                                date:$scope.date,
                                disposition:$scope.disposition,
                                age_group:$scope.age_group,
                                provider:$scope.selected_provider
                            }
                        )
                    );
                }
            };

            $scope.tableParams = new NgTableParams({
                sorting: { },
                count: 10
            }, {
                paginationMaxBlocks: 10,
                counts:[],
                getData: function (params)
                {

                    $scope.is_loading = true;
                    $scope.get_data_error = '';
                    return api.getIntakeCalls(
                        {
                            count:params.count(),
                            page:params.page(),
                            filter:params.filter(),
                            sorting:params.sorting(),
                            date:$scope.date,
                            disposition:$scope.disposition,
                            age_group:$scope.age_group,
                            provider:$scope.selected_provider
                        }
                    )
                        .then(function(results)
                        {
                            params.total(results.total);
                            $scope.is_loading = false;
                            for(var i = 0; i < results.records.length; i++)
                            {
                                var call = results.records[i];
                                call.intake_date = moment(call.intake_date).format('M/D/YYYY h:mm a');
                                call.Disposition = $scope.dispositionTitle(call.Disposition);
                                if(call.assigned_site == null)
                                    call.assigned_site = 'No Site Assigned';
                                results.records[i] = call;
                            }
                            return results.records;
                        })
                        .catch(function(error)
                        {
                            $scope.is_loading = false;
                            alerts.error('Error', 'Unable to pull Intake Calls from the database! Please try again!');
                        });
                }

            });
            $scope.dispositionTitle = function(id)
            {
                switch(Number(id))
                {
                    case 1:	return "Intake Evaluation";
                    case 2:	return "Call Back";
                    case 3:	return "Partial Hospitalization";
                    case 4: return "Information Only";
                    case 5:	return "Crisis Evaluation";
                    default: return "No Disposition specified";
                }

            }
        }
    ]);
})(window.NewOaklandDashboard);

