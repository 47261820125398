/**
 * .
 */
(function(app)
{
    app.controller('CareChartsCtrl', [
        '$scope', 'api', 'alerts',
        function($scope, api, alerts)
        {
            $scope.dates =
            {
                from:new Date(moment().subtract(2,'week').format('MM/DD/YYYY')),
                thru:new Date(moment().format('MM/DD/YYYY'))
            };
            $scope.providers = [
                {provider_id:"-1", provider_full_name:'All Providers'}
            ];
            $scope.selected_provider = "-1";
            (function()
            {
                api
                    .getProviders()
                    .then(function(results)
                    {
                        angular.forEach(results.providers, function(value)
                        {
                            if(value.provider_full_name.indexOf(' Outpatient') !== -1) {
                                $scope.providers.push(value);
                            }
                        });

                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                    })
            })();


            $scope.loading = true;

            $scope.get_data = function () {
                $scope.loading = true;
                api.AccessToCare({dates:$scope.dates, provider:$scope.selected_provider})
                    .then(function(results)
                    {
                        $scope.loading = false;
                        $scope.data = results;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                        $scope.loading = false;
                    });
            };

            $scope.get_data();

        }
    ])
})(window.NewOaklandDashboard);