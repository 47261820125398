/**
 * Created by mwillard on 10/5/16.
 */
(function(app)
{
    app.controller('VolumeDataReportCtrl',[
        '$scope','NgTableParams', 'api', 'alerts', '$location', '$sce', '$httpParamSerializer',
        function($scope, NgTableParams, api, alerts, $location, $sce, $httpParamSerializer)
        {
            $scope.is_loading = false;
            $scope.loading_providers = true;
            $scope.loading_statuses = true;
            $scope.selectable_status = [];
            $scope.get_data_error = '';
            $scope.total_open = 0;
            $scope.total_op = 0;
            $scope.total_php = 0;
            $scope.filters = {};

            $scope.totals = {};

            $scope.providers = [
                {
                    id:'',
                    title:'All Providers'
                }
            ];

            (function()
            {
                $scope.selectable_status.length = 0;
                $scope.search_params = $location.search();
                if($scope.search_params.from)
                {

                    $scope.date.from = new Date(moment($scope.search_params.from).format('MM/DD/YYYY'));
                    $scope.date.thru = new Date(moment($scope.search_params.thru).format('MM/DD/YYYY'));
                    $scope.selected_provider = $scope.search_params.provider;
                }
                api
                    .getProviders()
                    .then(function(results)
                    {

                        for(var i = 0; i < results.providers.length; i++)
                        {
                            var provider = results.providers[i];
                            $scope.providers.push(
                                {
                                    id: provider.provider_id,
                                    title: provider.provider_short_name,
                                    selected:$scope.selected_provider && $scope.selected_provider == provider.provider_id ? true : false
                                }
                            )
                        }
                        $scope.loading_providers = false;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                        $scope.loading_providers = false;
                    });
                api
                    .getAllStatuses()
                    .then(function(results)
                    {

                        for(var i = 0; i < results.records.length; i++)
                        {
                            var status = results.records[i];
                            $scope.selectable_status.push(
                                {
                                    id:status,
                                    title:status
                                }
                            )
                        }
                        $scope.loading_statuses = false;
                    })
                    .catch(function(error)
                    {
                        alerts.error('Error', error);
                        $scope.loading_statuses = false;
                    });
            })();

            $scope.$watch('date', function()
            {
                $scope.tableParams.reload();

            },true);
            $scope.$watch('selected_provider', function()
            {
                $scope.tableParams.reload();
            });

            $scope.doFilter = function ()
            {
                $scope.tableParams.reload();
            };

            $scope.getExport = function ()
            {
                var url = $scope.tableParams.url();

                for (var i in $scope.filters)
                {
                    if ($scope.filters.hasOwnProperty(i))
                    {
                        var value = $scope.filters[i];

                        if (value !== '')
                        {
                            url['filter[' + i +']'] = value;
                        }
                    }
                };

                if ($scope.tableParams.total() > 10000)
                {
                    alerts.confirm(
                        'Warning',
                        'There are more than 10000 records in this export. It may take a long to generate. Are you sure you want to continue?' +
                        ' It is recommended that you filter the results reduce to the number of records.',
                        function (){
                            window.open('/api/data/volume-data?export=1&' + $httpParamSerializer(
                                    {
                                        export:1,
                                        count:$scope.tableParams.count(),
                                        page:$scope.tableParams.page(),
                                        filter:$scope.tableParams.filter(),
                                        sorting:$scope.tableParams.sorting(),

                                        provider:$scope.selected_provider
                                    }
                                )
                            ); },
                        function (){}
                    );
                }
                else
                {
                    window.open('/api/data/volume-data?export=1&' + $httpParamSerializer(
                            {
                                export:1,
                                count:$scope.tableParams.count(),
                                page:$scope.tableParams.page(),
                                filter:$scope.tableParams.filter(),
                                sorting:$scope.tableParams.sorting(),

                                provider:$scope.selected_provider
                            }
                        )
                    );
                }
            };

            $scope.tableParams = new NgTableParams({
                sorting: { },
                count: 20
            }, {
                paginationMaxBlocks: 20,
                counts:[],
                getData: function (params)
                {

                    $scope.is_loading = true;
                    $scope.get_data_error = '';
                    return api.volumeData(
                        {
                            count:params.count(),
                            page:params.page(),
                            filter:params.filter(),
                            sorting:params.sorting(),

                            provider:$scope.selected_provider
                        }
                    )
                        .then(function(results)
                        {
                            params.total(results.total);
                            $scope.is_loading = false;
                            $scope.total_open = 0;
                            $scope.total_op = 0;
                            $scope.total_php = 0;
                            for(var i = 0; i < results.records.length; i++)
                            {
                                var site = results.records[i];

                                $scope.total_open += Number(site.open_cases);
                                if([8,9,10,11,12,25].indexOf(Number(site.provider_id)) >= 0)
                                    $scope.total_op += Number(site.open_cases);
                                else
                                    $scope.total_php += Number(site.open_cases);
                            }
                            return results.records;
                        })
                        .catch(function(error)
                        {
                            $scope.is_loading = false;
                            alerts.error('Error', 'Unable to pull from the database! Please try again!');
                        });
                }

            });


        }
    ])
})(window.NewOaklandDashboard);