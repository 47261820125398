(function (app)
{
    'use strict';

    app.controller('ChartsCtrl', [
        '$scope',
        function ($scope)
        {
            $scope.timeframes = {
                day: 'Day',
                month: 'Month',
                quarter: 'Quarter',
                year: 'Year'
            };

            $scope.types = {
                charges: "Charges - By Service Date",
                revenue_service_date: "Revenue - by Service Date",
                revenue_payment_date: "Revenue - by Payment Date",
                time_to_encounter: "Time to First Encounter",
                time_to_discharge: "Time to Discharge"
            };

            $scope.timeframe = 'year';
            $scope.type = 'revenue_service_date';
            $scope.timeframe_label = $scope.timeframes[$scope.timeframe];
            $scope.type_label = $scope.types[$scope.type];

            $scope.$watch('timeframe', function ()
            {
                $scope.timeframe_label = $scope.timeframes[$scope.timeframe];
            });

            $scope.$watch('type', function ()
            {
                $scope.type_label = $scope.types[$scope.type];

                switch ($scope.type)
                {
                    case 'charges':
                        $scope.valuePrefix = '$';
                        $scope.valueSuffix = '';
                        $scope.widgets = [
                            '/templates/charts/open-vs-closed.html',
                            '/templates/charts/age.html',
                            '/templates/charts/zip.html'
                        ];
                        break;
                    case 'revenue_service_date':
                    case 'revenue_payment_date':
                        $scope.valuePrefix = '$';
                        $scope.valueSuffix = '';
                        $scope.widgets = [
                            '/templates/charts/open-vs-closed.html',
                            '/templates/charts/age.html',
                            '/templates/charts/zip.html',
                            '/templates/charts/type.html'
                        ];
                        break;
                    case 'time_to_encounter':
                        $scope.valuePrefix = '';
                        $scope.valueSuffix = ' Days';
                        $scope.widgets = [
                            '/templates/charts/open-vs-closed.html',
                            '/templates/charts/age.html',
                            '/templates/charts/zip.html'
                        ];
                        break;
                    case 'time_to_discharge':
                        $scope.valuePrefix = '';
                        $scope.valueSuffix = ' Days';
                        $scope.widgets = [
                            '/templates/charts/age.html',
                            '/templates/charts/zip.html'
                        ];
                        break;
                }
            });

            $scope.widgets = [
                '/templates/charts/open-vs-closed.html',
                '/templates/charts/age.html',
                '/templates/charts/zip.html',
                '/templates/charts/type.html'
            ];
        }
    ]);
})(window.NewOaklandDashboard);
